* {
  box-sizing: border-box;
  font-family: 'Descriptions', 'serif', 'Roboto', 'Segoe UI';
}

@font-face {
  font-family: "Descriptions";
  src: local("Descriptions"),
   url("./fonts/Mont.otf") format("opentype");
  font-weight: normal;
}
@font-face {
  font-family: "Titles";
  src: local("Titles"),
  url("./fonts/bobby-jones-soft.otf") format("opentype");
  font-weight: normal;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

app {
  height: 100vh;
}